import React, { useRef, useCallback } from "react";
import { Grid } from "@mui/material";
import { SortFilter } from '@securspace/securspace-ui-kit';
import { filterByOptions, filterBySelectOption, groupOption } from "./data";
import { getAllLocationsByBuyer } from "../../components/location/requests/location-requests";
import useGetRequestBody from "../../hooks/useGetRequestBody";
import {withSnackbar} from "../hocs/withSnackbar";

const BuyerInventoryFilter = React.memo(({
  buyerAccountId,
  setSortBy,
  setSelectedLocation,
  setDriverName,
  setEquipmentType,
  setBookingNumber,
  setSortDir,
  setEquipmentNumber,
  snackbarShowMessage
}) => {

  const locations = useGetRequestBody(getAllLocationsByBuyer, [buyerAccountId], snackbarShowMessage);
  const initialRender = useRef(true);

  const parseFilters = (filterArray) => {
    const equipment = filterArray.find(item => item.name === "equipment")?.value
    const bookingNumber = filterArray.find(item => item.name === "bookingNumber")?.value
    const driverName = filterArray.find(item => item.name === "driverName")?.value
    const equipmentNumber = filterArray.find(item => item.name === "equipmentNumber")?.value

    return {
      equipment,
      bookingNumber,
      driverName,
      equipmentNumber
    }
  }

  const handleCallback = useCallback((e) => {
    if (!initialRender.current) {
      setSortBy(e.sortBy);
      setBookingNumber(parseFilters(e.filters).bookingNumber);
      setEquipmentNumber(parseFilters(e.filters).equipmentNumber);
      setEquipmentType(parseFilters(e.selectFilters).equipment);
      setDriverName(parseFilters(e.filters).driverName);
      setSelectedLocation(e.location);
      setSortDir(e.sortDir)
    } else {
      initialRender.current = false;
    }
  }, [setEquipmentNumber, setSortDir, setSortBy, setBookingNumber, setEquipmentType, setDriverName, setSelectedLocation, parseFilters])

  return (
    <Grid item>
      <SortFilter
        filterCallback={handleCallback}
        locations={locations}
        filterByOption={filterByOptions}
        sortByOption={groupOption}
        filterBySelectOption={filterBySelectOption}
        defaultSortBy={"checkInDate"}
        sortDir={"desc"}
      />
    </Grid>
  )
})

export default withSnackbar(BuyerInventoryFilter);
