import React, { useState, useEffect } from 'react'
import { Typography, Grid } from "@mui/material";
import { Pagination, formatter, InfoCardLoader } from '@securspace/securspace-ui-kit'
import { withSnackbar } from '../../components/hocs/withSnackbar'
import { BuyerInventoryFilter, BuyerInventoryTable } from '../../components/buyer-inventory';
import { getErrorMessageForStandardResponse } from '../../util/NetworkErrorUtil';
import { getAllBuyerInventory } from '../../components/buyer-inventory/requests/buyer-inventory-requests';
import SupplierBookingTitle from '../../components/supplier-booking/SupplierBookingTitle';
import { expandAllItems, toggleItem } from '../invoices/BuyerInvoicesReport';

const BuyerInventoryReport = ({ account, snackbarShowMessage }) => {

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(50);
    const [totalNumberResults, setTotalNumberResults] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState();
    const [sortBy, setSortBy] = useState('');
    const [driverName, setDriverName] = useState('')
    const [equipmentType, setEquipmentType] = useState('')
    const [equipmentNumber, setEquipmentNumber] = useState('');
    const [bookingNumber, setBookingNumber] = useState('')
    const [sortDir, setSortDir] = useState('')
    const [expandedItems, setExpandedItems] = useState({});
    const [expandedAll, setExpandedAll] = useState(false);
    const [inventoryReports, setInventoryReports] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const { id } = account;
        if (id) {
            setLoading(true)
            const requestParams = {
                buyerAccountId: id,
                page: page,
                size: size,
                sortBy: sortBy,
                locationId: selectedLocation?.id,
                driverName: driverName?.trim(),
                equipmentType,
                bookingNumber: bookingNumber?.trim(),
                sortDir,
                equipmentNumber
            }

            getAllBuyerInventory(requestParams)
                .then(({ body }) => {
                    const { content, count } = body;
                    const data = content?.map((item) => {

                        return {
                            ...item,
                            assetType: formatter(item.assetType),
                        };
                    });
                    setInventoryReports(data)
                    setTotalNumberResults(count);
                    setLoading(false)
                    setExpandedItems({})
                })
                .catch(err => {
                    snackbarShowMessage(getErrorMessageForStandardResponse(err), "error", 15000)
                    setLoading(false)
                });
        }

    }, [account, page, size, equipmentNumber, selectedLocation, equipmentType, driverName, bookingNumber, sortBy, sortDir]);

    const handlePageChange = (_, selectedPage) => {
        setPage(selectedPage)
    }

    const handleSizeChange = (event) => {
        setSize(event.target.value)
    }

    
const handleToggle = (id) => toggleItem(id, setExpandedItems);
const handleExpandAll = () => expandAllItems(inventoryReports, 'id', setExpandedItems, expandedAll, setExpandedAll);


    return (
        <Grid item container flexDirection={'column'}>
            <Typography variant='h5' component='h1' mb={4}>Current Inventory</Typography>
            <Grid mb={4}>
                <BuyerInventoryFilter
                    buyerAccountId={account.id}
                    setSortBy={setSortBy}
                    setSelectedLocation={setSelectedLocation}
                    setDriverName={setDriverName}
                    setEquipmentType={setEquipmentType}
                    setBookingNumber={setBookingNumber}
                    setSortDir={setSortDir}
                    setEquipmentNumber={setEquipmentNumber}
                />
            </Grid>
            {inventoryReports && inventoryReports.length > 0 &&
                <SupplierBookingTitle
                    title={selectedLocation?.locationName || 'All'}
                    count={inventoryReports?.length}
                    data={inventoryReports}
                    expandedAll={expandedAll}
                    setExpandedItems={setExpandedItems}
                    setExpandedAll={setExpandedAll}
                    isShowCollapseButton={true}
                    handleExpandAll={handleExpandAll}
                    reportName='Buyer Inventory Report'
                    expandedItems={expandedItems}
                />
            }
            {
                loading ? [...Array(10).keys()].map((_, index) => (
                    <InfoCardLoader key={index} />
                )) : <BuyerInventoryTable
                    inventoryReports={inventoryReports}
                    locationId={selectedLocation?.id || ''}
                    driverName={driverName}
                    equipmentType={equipmentType}
                    bookingNumber={bookingNumber}
                    sortBy={sortBy}
                    handleToggle={handleToggle}
                    expandedItems={expandedItems}
                />
            }

            {inventoryReports && inventoryReports.length > 0 && <Grid item>
                <Pagination
                    count={totalNumberResults}
                    rowsPerPage={size}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleSizeChange}
                    variant="tablePagination" />
            </Grid>}
        </Grid>
    )
}

export default withSnackbar(BuyerInventoryReport)
