import React from 'react';
import {ClassNameMap} from "@mui/styles";
import {Button} from "@mui/material";
import {fetchDownloadFile} from "../../../util/FileUtils";
import DownloadCsvIcon from "../../icons/DownloadCsvIcon";

const DownloadCSVButton = (props: {
    title?: string,
    reportUrl: string,
    onError?: (event) => void,
    className?: ClassNameMap
}) => {
    const {title, reportUrl, onError, className} = props;
    const handleClick = async () => {
        try {
            fetchDownloadFile(reportUrl);
        } catch(error) {
            onError && onError(error);
        }
    };

    return (
        <Button
            variant={"contained"}
            startIcon={<DownloadCsvIcon/>}
            onClick={handleClick}
            className={className}
        >
            {title || "Download"}
        </Button>
    );
};

export default DownloadCSVButton;