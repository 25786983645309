export const UserType = Object.freeze({
  OWNER: "OWNER",
  PRIMARY_OWNER: "PRIMARY_OWNER",
  ADMIN: "ADMIN",
  GATE_MANAGER: "GATEMANAGER",
  GATE_CLERK: "GATECLERK",
  DISPATCHER: "DISPATCHER"
})

export const AccountType = Object.freeze({
  BUYER: "Buyer",
  SUPPLIER: "Supplier",
  ADMIN: "Admin"
})

export const SubscriptionType = Object.freeze({
  MARKETPLACE_ONLY: "MARKETPLACE_ONLY",
  GMS_LITE: "GMS_LITE",
  GMS_PRO: "GMS_PRO"
})

export const AuthorityType = Object.freeze({
  ACCOUNT: "ACCOUNT"
})

export const PaymentType = Object.freeze({
  ACH: "ACH",
  CARD: "CARD"
})

export const BankAccountStatus = Object.freeze({
  VERIFIED: 'verified',
  UNVERIFIED: 'unverified'
});

export const DwollaBankAccountType = Object.freeze({
  CHECKING: "Checking",
  SAVINGS: "Savings"
});

export const VerificationType = Object.freeze({
  INSTANT: "INSTANT",
  MICRO_DEPOSIT: "MICRO_DEPOSIT"
})

export const BankAccountType = Object.freeze({
  COMPANY_CHECKING: {value: "COMPANY_CHECKING", label: "Company - Checking"},
  COMPANY_SAVINGS: {value: "COMPANY_SAVINGS", label: "Company - Savings"},
  INDIVIDUAL_CHECKING: {value: "INDIVIDUAL_CHECKING", label: "Individual - Checking"},
  INDIVIDUAL_SAVINGS: {value: "INDIVIDUAL_SAVINGS", label: "Individual - Savings"}
})

export const LogoType = Object.freeze({
  LOGO_TRANSPARENT: "logo-transparent",
  LOGO_WHITE: "logo-white"
})

export const UserTypeName = Object.freeze({
  GATE_CLERK: 'Gate Clerk',
  GATE_MANAGER: 'Gate Manager',
  DISPATCHER: 'Dispatcher',
  ADMIN: 'Administrator',
  ACCOUNT_OWNER: 'Account Owner'
})

export const UserTypeDBFormat = Object.freeze({
  ROLE_USERTYPE_GATECLERK: 'ROLE_USERTYPE_GATECLERK',
  ROLE_USERTYPE_GATEMANAGER: 'ROLE_USERTYPE_GATEMANAGER',
  ROLE_USERTYPE_DISPATCHER: 'ROLE_USERTYPE_DISPATCHER',
  ROLE_USERTYPE_ADMIN: 'ROLE_USERTYPE_ADMIN',
  ROLE_USERTYPE_OWNER: 'ROLE_USERTYPE_OWNER',
  ROLE_USERTYPE_PRIMARY_OWNER: 'ROLE_USERTYPE_PRIMARY_OWNER'
})

export const UserRoleToTypeName = Object.freeze({
  [UserTypeDBFormat.ROLE_USERTYPE_GATECLERK]: UserTypeName.GATE_CLERK,
  [UserTypeDBFormat.ROLE_USERTYPE_GATEMANAGER]: UserTypeName.GATE_MANAGER,
  [UserTypeDBFormat.ROLE_USERTYPE_DISPATCHER]: UserTypeName.DISPATCHER,
  [UserTypeDBFormat.ROLE_USERTYPE_ADMIN]: UserTypeName.ADMIN,
  [UserTypeDBFormat.ROLE_USERTYPE_OWNER]: UserTypeName.ADMIN, // NOTE: Owners are displayed as Administrators
  [UserTypeDBFormat.ROLE_USERTYPE_PRIMARY_OWNER]: UserTypeName.ACCOUNT_OWNER
});

export const BookingReasonDeclined = Object.freeze({
  PARTNER_DECLINED: 'PARTNER_DECLINED',
  AUTO_DECLINED: 'AUTO_DECLINED'
})

export const DateFormats = Object.freeze({
  DATE: "MM/DD/YYYY hh:mm A",
  DAY: "MM/DD/YYYY",
  MONTH_YEAR: "MM/YYYY",
})

export const ChargeTypes = Object.freeze({
  BOOKING_CHARGE: "Booking Payment",
  CANCELLATION_REFUND: "Cancellation Refund",
  OVERAGE_CHARGE: "Overage Payment",
  DATE_ADJUST_CHARGE: "Date Adjust Payment",
  DATE_ADJUST_REFUND: "Date Adjust Refund",
  OVERSTAY_CHARGE: "Overstay Payment",
  ADD_SPACE_CHARGE: "Add Space Charge",
  SPACE_REMOVED_REFUND: "Space Removed Refund",
  DAILY_OVERAGE_CHARGE: "Daily Overage Payment",
});

export const BookingSubscriptionStatus = Object.freeze({
  ACTIVE: "Active",
  DECLINED: "Declined",
  CANCELLED: "Cancelled",
  NONE: "None"
});

export const BookingDurationType = Object.freeze({
  DAILY: "Daily",
  MONTHLY: "Monthly",
});

export const BookingStatus = Object.freeze({
  INCOMPLETE: "Incomplete",
  PENDING: "Pending",
  PENDING_VERIFICATION_REQUIRED: "Pending-Verification Required",
  APPROVED: "Approved",
  APPROVED_ADJUSTED: "Approved-Adjusted",
  DECLINED: "Declined",
  CANCELLED: "Cancelled",
  MANUAL_PAYMENT: "Manual-Payment",
  ERROR: "Error",
  PROCESSING_ACH_PAYMENT: "Processing-ACH-Payment",
  PROCESSING_DATE_ADJUST_PENDING_ACH_PAYMENT: "Processing-ACH-Payment-Adjusted",
  PROCESSING_DATE_ADJUST_PENDING_ACH_REFUND: "Processing-ACH-Refund-Adjusted",
  PAYMENT_DECLINED: "Payment Declined",
  PAYMENT_DECLINED_ADJUSTED: "Payment Declined-Adjusted",
  REFUND_DECLINED_ADJUSTED: "Refund Declined-Adjusted",
});

export const BookingStatusesEligibleForCancellation = Object.freeze([
  BookingStatus.APPROVED,
  BookingStatus.PAYMENT_DECLINED,
  BookingStatus.PAYMENT_DECLINED_ADJUSTED,
  BookingStatus.PROCESSING_ACH_PAYMENT,
]);

export const BookingStatusesEligibleForPaymentMethodUpdate = Object.freeze([
  BookingStatus.APPROVED,
  BookingStatus.APPROVED_ADJUSTED,
  BookingStatus.PAYMENT_DECLINED,
  BookingStatus.PAYMENT_DECLINED_ADJUSTED,
]);

export const EquipmentLengthsConstant = Object.freeze({
  TWENTY_FIVE_FOOT: "25' MAX (Truck Only)",
  FORTY_FIVE_FOOT: "45' MAX (Small Container)",
  FIFTY_THREE_FOOT: "53' MAX (Large Container)",
  EIGHTY_THREE_FOOT: "83' MAX (Truck + Trailer)"
});

export const PaymentProcessor = Object.freeze({
  STRIPE: 'Stripe',
  DWOLLA: 'Dwolla'
});

export const invoiceStatusesThatCanBeCancelled = [
  "PAYMENT_AUTHORIZED",
  "PAYMENT_NOT_SUBMITTED",
  "PAYMENT_CANCELLED",
  "PAYMENT_FAILED",
  "SECURSPACE_PAYOUT_FAILED",
  "SECURSPACE_PAYOUT_CANCELED",
  "SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED",
  "SUPPLIER_PAYOUT_FAILED",
  "MANUAL_PAYMENT",
  "DISPUTED",
];

export const invoicesThatCanBeResent = [
  "DISPUTED",
];

export const invoiceCancelStatuses = {
  UNCOLLECTIBLE: 'UNCOLLECTIBLE',
  CANCEL_TERM_CHANGES: 'CANCEL_TERM_CHANGES',
  CANCEL_BOOKING_CANCELLED: 'CANCEL_BOOKING_CANCELLED',
  CANCEL_BILLING_REQUIREMENTS: 'CANCEL_BILLING_REQUIREMENTS',
  CANCEL_OTHER: 'CANCEL_OTHER'
};

export const ServiceFeeType = Object.freeze({
  LIFT_FEE: 'LIFT_FEE',
  GATE_FEE: 'GATE_FEE',
  VALET_MOVING_FEE: 'VALET_MOVING_FEE',
  TRANSLOADING: 'TRANSLOADING',
  CONTAINER_FREIGHT_STABILIZATION: 'CONTAINER_FREIGHT_STABILIZATION',
  DRAYAGE: 'DRAYAGE',
  EV_CHARGING: 'EV_CHARGING',
  MUDFLAP_REPAIR: 'MUDFLAP_REPAIR',
  TRUCK_OR_TRAILER_WASH: 'TRUCK_OR_TRAILER_WASH',
  HEADLIGHT_TAILLIGHT_REPAIR_REPLACEMENT: 'HEADLIGHT_TAILLIGHT_REPAIR_REPLACEMENT',
  TIRE_REPAIR_REPLACEMENT: 'TIRE_REPAIR_REPLACEMENT',
  OIL_CHANGE: 'OIL_CHANGE',
  GENERAL_CHASSIS_REPAIR: 'GENERAL_CHASSIS_REPAIR',
  GENERAL_CONTAINER_REPAIR: 'GENERAL_CONTAINER_REPAIR',
});

export const ServiceFeeTypeLabel = Object.freeze({
  [ServiceFeeType.LIFT_FEE]: 'Lift Fee',
  [ServiceFeeType.GATE_FEE]: 'Gate Fee',
  [ServiceFeeType.VALET_MOVING_FEE]: 'Valet/Moving Fee',
  [ServiceFeeType.TRANSLOADING]: 'Transloading',
  [ServiceFeeType.CONTAINER_FREIGHT_STABILIZATION]: 'Container Freight Stabilization',
  [ServiceFeeType.DRAYAGE]: 'Drayage',
  [ServiceFeeType.EV_CHARGING]: 'EV Charging',
  [ServiceFeeType.MUDFLAP_REPAIR]: 'Mudflap Repair',
  [ServiceFeeType.TRUCK_OR_TRAILER_WASH]: 'Truck or Trailer Wash',
  [ServiceFeeType.HEADLIGHT_TAILLIGHT_REPAIR_REPLACEMENT]: 'Headlight/taillight Repair/Replacement',
  [ServiceFeeType.TIRE_REPAIR_REPLACEMENT]: 'Tire Repair/Replacement',
  [ServiceFeeType.OIL_CHANGE]: 'Oil Change',
  [ServiceFeeType.GENERAL_CHASSIS_REPAIR]: 'General Chassis Repair',
  [ServiceFeeType.GENERAL_CONTAINER_REPAIR]: 'General Container Repair',
});

export const MAINTENANCE_AND_REPAIR_LABEL = Object.freeze('Maintenance and Repair');

export const MaintenanceAndRepairSubTypes = Object.freeze([
  ServiceFeeType.MUDFLAP_REPAIR,
  ServiceFeeType.TRUCK_OR_TRAILER_WASH,
  ServiceFeeType.HEADLIGHT_TAILLIGHT_REPAIR_REPLACEMENT,
  ServiceFeeType.TIRE_REPAIR_REPLACEMENT,
  ServiceFeeType.OIL_CHANGE,
  ServiceFeeType.GENERAL_CHASSIS_REPAIR,
  ServiceFeeType.GENERAL_CONTAINER_REPAIR,
]);

export const BookingTransactionType = Object.freeze({
  BOOKING_CHARGE: 'BOOKING_CHARGE',
  CANCELLATION_REFUND: 'CANCELLATION_REFUND',
  SPACE_REMOVED_REFUND: 'SPACE_REMOVED_REFUND',
  DATE_ADJUST_CHARGE: 'DATE_ADJUST_CHARGE',
  DATE_ADJUST_REFUND: 'DATE_ADJUST_REFUND',
  ADD_SPACE_CHARGE: 'ADD_SPACE_CHARGE',
  OVERAGE_CHARGE: 'OVERAGE_CHARGE',
  DAILY_OVERAGE_CHARGE: 'DAILY_OVERAGE_CHARGE',
  OVERSTAY_CHARGE: 'OVERSTAY_CHARGE',
  BILLING_CORRECTION_REFUND: 'BILLING_CORRECTION_REFUND',
  SERVICE_CHARGE: 'SERVICE_CHARGE',
  AGS_FEE: 'AGS_FEE',
});

export const BookingTransactionTypeLabel = Object.freeze({
  [BookingTransactionType.BOOKING_CHARGE]: 'Booking Payment',
  [BookingTransactionType.CANCELLATION_REFUND]: 'Cancellation Refund',
  [BookingTransactionType.SPACE_REMOVED_REFUND]: 'Space Removed Refund',
  [BookingTransactionType.DATE_ADJUST_CHARGE]: 'Date Adjust Payment',
  [BookingTransactionType.DATE_ADJUST_REFUND]: 'Date Adjust Refund',
  [BookingTransactionType.ADD_SPACE_CHARGE]: 'Add Space Charge',
  [BookingTransactionType.OVERAGE_CHARGE]: 'Overage Payment',
  [BookingTransactionType.DAILY_OVERAGE_CHARGE]: 'Daily Overage Payment',
  [BookingTransactionType.OVERSTAY_CHARGE]: 'Overstay Payment',
  [BookingTransactionType.BILLING_CORRECTION_REFUND]: '',
  [BookingTransactionType.SERVICE_CHARGE]: 'Service Charge',
  [BookingTransactionType.AGS_FEE]: 'AGS Fee',
});

export const BookingTransactionStatusType = Object.freeze({
  BOOKING_INCOMPLETE: 'BOOKING_INCOMPLETE',
  CHARGE_PENDING: 'CHARGE_PENDING',
  CHARGE_ON_HOLD: 'CHARGE_ON_HOLD',
  PAYMENT_NOT_SUBMITTED: 'PAYMENT_NOT_SUBMITTED',
  REFUND_REQUESTED: 'REFUND_REQUESTED',
  REFUND_DENIED: 'REFUND_DENIED',
  MANUAL_PAYMENT: 'MANUAL_PAYMENT',
  PAYMENT_AUTHORIZED: 'PAYMENT_AUTHORIZED',
  PAYMENT_CANCELLED: 'PAYMENT_CANCELLED',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_SUCCEEDED: 'PAYMENT_SUCCEEDED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  SECURSPACE_PAYOUT_PENDING: 'SECURSPACE_PAYOUT_PENDING',
  SECURSPACE_PAYOUT_SUCCEEDED: 'SECURSPACE_PAYOUT_SUCCEEDED',
  SECURSPACE_PAYOUT_FAILED: 'SECURSPACE_PAYOUT_FAILED',
  SECURSPACE_PAYOUT_CANCELED: 'SECURSPACE_PAYOUT_CANCELED',
  SUPPLIER_PAYOUT_INITIATING: 'SUPPLIER_PAYOUT_INITIATING',
  SUPPLIER_PAYOUT_PENDING: 'SUPPLIER_PAYOUT_PENDING',
  SUPPLIER_PAYOUT_ON_HOLD: 'SUPPLIER_PAYOUT_ON_HOLD',
  SUPPLIER_PAYOUT_SUCCEEDED: 'SUPPLIER_PAYOUT_SUCCEEDED',
  SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED: 'SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED',
  SUPPLIER_PAYOUT_SUCCEEDED_SECURSPACE_FEE_PAYOUT_FAILED: 'SUPPLIER_PAYOUT_SUCCEEDED_SECURSPACE_FEE_PAYOUT_FAILED',
  SUPPLIER_PAYOUT_FAILED: 'SUPPLIER_PAYOUT_FAILED',
  UNCOLLECTIBLE: 'UNCOLLECTIBLE',
  CANCEL_TERM_CHANGES: 'CANCEL_TERM_CHANGES',
  CANCEL_BOOKING_CANCELLED: 'CANCEL_BOOKING_CANCELLED',
  CANCEL_BILLING_REQUIREMENTS: 'CANCEL_BILLING_REQUIREMENTS',
  CANCEL_OTHER: 'CANCEL_OTHER',
  DISPUTED: 'DISPUTED',
});

export const BookingTransactionStatusTypeLabel = Object.freeze({
  [BookingTransactionStatusType.CHARGE_PENDING]: 'Charge Pending',
  [BookingTransactionStatusType.BOOKING_INCOMPLETE]: 'Booking Incomplete',
  [BookingTransactionStatusType.CHARGE_ON_HOLD]: 'Charge On Hold',
  [BookingTransactionStatusType.PAYMENT_NOT_SUBMITTED]: 'Payment Not Submitted',
  [BookingTransactionStatusType.REFUND_REQUESTED]: 'Refund Requested',
  [BookingTransactionStatusType.REFUND_DENIED]: 'Refund Denied',
  [BookingTransactionStatusType.MANUAL_PAYMENT]: 'Manual Payment',
  [BookingTransactionStatusType.PAYMENT_AUTHORIZED]: 'Payment Authorized',
  [BookingTransactionStatusType.PAYMENT_CANCELLED]: 'Payment Cancelled',
  [BookingTransactionStatusType.PAYMENT_PENDING]: 'Payment Pending',
  [BookingTransactionStatusType.PAYMENT_SUCCEEDED]: 'Payment Succeeded',
  [BookingTransactionStatusType.PAYMENT_FAILED]: 'Payment Failed',
  [BookingTransactionStatusType.SECURSPACE_PAYOUT_PENDING]: 'SecurSpace Payout Pending',
  [BookingTransactionStatusType.SECURSPACE_PAYOUT_SUCCEEDED]: 'SecurSpace Payout Succeeded',
  [BookingTransactionStatusType.SECURSPACE_PAYOUT_FAILED]: 'SecurSpace Payout Failed',
  [BookingTransactionStatusType.SECURSPACE_PAYOUT_CANCELED]: 'SecurSpace Payout Cancelle',
  [BookingTransactionStatusType.SUPPLIER_PAYOUT_INITIATING]: 'Supplier Payout Initiating',
  [BookingTransactionStatusType.SUPPLIER_PAYOUT_PENDING]: 'Supplier Payout Pending',
  [BookingTransactionStatusType.SUPPLIER_PAYOUT_ON_HOLD]: 'Supplier Payout On Hold',
  [BookingTransactionStatusType.SUPPLIER_PAYOUT_SUCCEEDED]: 'Supplier Payout Succeeded',
  [BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED]: 'Supplier Payout Failed, SecurSpace Fee Payout Succeeded',
  [BookingTransactionStatusType.SUPPLIER_PAYOUT_SUCCEEDED_SECURSPACE_FEE_PAYOUT_FAILED]: 'Supplier Payout Succeeded, SecurSpace Fee Payout Failed',
  [BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED]: 'Supplier Payout Failed',
  [BookingTransactionStatusType.UNCOLLECTIBLE]: 'Uncollectable',
  [BookingTransactionStatusType.CANCEL_TERM_CHANGES]: 'Cancelled: Term Changes',
  [BookingTransactionStatusType.CANCEL_BOOKING_CANCELLED]: 'Cancelled: Booking Cancelled',
  [BookingTransactionStatusType.CANCEL_BILLING_REQUIREMENTS]: 'Cancelled: Billing Requirements',
  [BookingTransactionStatusType.CANCEL_OTHER]: 'Cancelled',
  [BookingTransactionStatusType.DISPUTED]: 'Disputed',
});

export const buyerServiceFeeStatusesThatArePending = [
  BookingTransactionStatusType.CHARGE_PENDING,
  BookingTransactionStatusType.PAYMENT_NOT_SUBMITTED,
  BookingTransactionStatusType.PAYMENT_AUTHORIZED,
  BookingTransactionStatusType.PAYMENT_PENDING,
];

export const buyerServiceFeeStatusesThatAreComplete = [
  BookingTransactionStatusType.MANUAL_PAYMENT,
  BookingTransactionStatusType.PAYMENT_CANCELLED,
  BookingTransactionStatusType.PAYMENT_SUCCEEDED,
  BookingTransactionStatusType.PAYMENT_FAILED,
  BookingTransactionStatusType.SECURSPACE_PAYOUT_PENDING,
  BookingTransactionStatusType.SECURSPACE_PAYOUT_SUCCEEDED,
  BookingTransactionStatusType.SECURSPACE_PAYOUT_FAILED,
  BookingTransactionStatusType.SECURSPACE_PAYOUT_CANCELED,
  BookingTransactionStatusType.SUPPLIER_PAYOUT_INITIATING,
  BookingTransactionStatusType.SUPPLIER_PAYOUT_PENDING,
  BookingTransactionStatusType.SUPPLIER_PAYOUT_ON_HOLD,
  BookingTransactionStatusType.SUPPLIER_PAYOUT_SUCCEEDED,
  BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED,
  BookingTransactionStatusType.SUPPLIER_PAYOUT_SUCCEEDED_SECURSPACE_FEE_PAYOUT_FAILED,
  BookingTransactionStatusType.SUPPLIER_PAYOUT_FAILED,
];

export const LOGIN_PATH = Object.freeze('/login');

export const LoginRedirectPathnameBlockList = Object.freeze([
  '/',
  LOGIN_PATH,
  '/logout', // lol
  '/how-it-works',
  '/about',
  '/contact',
  '/faq',
  '/gms-landing',
  '/exclusive-webinar-access-expert-insights-strategies',
  '/partner-signup',
  '/customer-signup',
  '/search',
  '/change-password',
  '/set-password',
  '/blog'
]);

export const LoginRedirectNestedPathnameBlockList = Object.freeze([
  '/location-profile',
  '/terms-of-use',
  '/privacy-policy',
  '/blog',
  '/account',
]);

export const PartnerSubscriptionTypes = Object.freeze({
  AGS_FEE: 'AGS_FEE',
  NO_SUBSCRIPTION: 'NO SUBSCRIPTION'
});

export const PartnerSubscriptionTypeLabel = Object.freeze({
  [PartnerSubscriptionTypes.AGS_FEE]: 'AGS FEE',
  [PartnerSubscriptionTypes.NO_SUBSCRIPTION]: 'NO SUBSCRIPTION',
});
export const DAYS_PER_MONTH = 30.42;

// trying out new combination of flow and proptypes for better type completion on text based attributes
// flow utilities aren't quite as good as typescript, so using a map with meaningless numbers here to convert to type
export const severityColorMap = Object.freeze({
  success: 0,
  info: 1,
  warning: 2,
  error: 3,
});
// this array is used since i can't pass flow types into proptype typings
export const severityColors = Object.keys(severityColorMap);
// create flow type based on keys of map created earlier, same as type SeverityColor = 'success' | 'info' | ...
export type SeverityColor = $Keys<typeof severityColorMap>;

export const PartnerSubscriptionTransactionStatusType = Object.freeze({
  CHARGE_SUCCEEDED: 'CHARGE_SUCCEEDED',
  CHARGE_PENDING: 'CHARGE_PENDING',
  CHARGE_FAILED: 'CHARGE_FAILED',
});

export const PartnerSubscriptionTransactionStatusTypeLabel = Object.freeze({
  [PartnerSubscriptionTransactionStatusType.CHARGE_SUCCEEDED]: 'Charge Succeeded',
  [PartnerSubscriptionTransactionStatusType.CHARGE_PENDING]: 'Charge Pending',
  [PartnerSubscriptionTransactionStatusType.CHARGE_FAILED]: 'Charge Failed',
});

export const superAdminList = [
  "bobby@secur.space",
  "mpoilvez@envasetechnologies.com",
  "anjelika.shine@envasetechnologies.com",
  "kareem.aljabr@envasetechnologies.com",
  "khastie@envasetechnologies.com",
  "deatra.lee@wisetechglobal.com",
  "emily.walstrom@wisetechglobal.com"
]

export const RECAPTCHA_KEY = "6LdyW6UUAAAAABFEKiLQV6Io3JkTQjSoj_kmdRFz";

export const CheckInTypesFilter = Object.freeze([
  "Overage Spaces",
  "Lift Fees: Loaded",
  "Lift Fees: Empty",
  "Gate Fees",
  "Reefer KW Usage",
  "Equipment Moving Fee"
]);

export const AccountTypes = Object.freeze({
  INDIVIDUAL_CHECKING: "INDIVIDUAL_CHECKING",
  COMPANY_CHECKING: "COMPANY_CHECKING",
  INDIVIDUAL_SAVINGS: "INDIVIDUAL_SAVINGS",
  COMPANY_SAVINGS: "COMPANY_SAVINGS",
});

export const AccountTypeLabel = Object.freeze({
  [AccountTypes.INDIVIDUAL_CHECKING]: "Individual - Checking",
  [AccountTypes.COMPANY_CHECKING]: "Company - Checking",
  [AccountTypes.INDIVIDUAL_SAVINGS]: "Individual - Savings",
  [AccountTypes.COMPANY_SAVINGS]: "Company - Savings",
});
export const listingImageBaseURL = 'https://s3-us-west-1.amazonaws.com/securspace-files/listing-images/';

export const payoutScheduleOptions = Object.freeze([
  {value: "MANUAL", label: "Manual"},
  {value: "DAILY", label: "Daily"},
  {value: "WEEKLY", label: "Weekly"},
  {value: "MONTHLY", label: "Monthly"}
]);

export const subscriptionTypeOptions = Object.freeze([
  {value: SubscriptionType.MARKETPLACE_ONLY, label: "Marketplace Only"},
  {value: SubscriptionType.GMS_LITE, label: "GMS Lite"},
  {value: SubscriptionType.GMS_PRO, label: "GMS Pro"}
]);

export const inventoryCorrectionReasons = Object.freeze({
  ASSET_DOES_NOT_BELONG_TO_BUYER: "ASSET_DOES_NOT_BELONG_TO_BUYER",
  ASSET_CHECKED_OUT_ON_WRONG_DATE: "ASSET_CHECKED_OUT_ON_WRONG_DATE",
  ASSET_NUMBER_ENTERED_INCORRECTLY: "ASSET_NUMBER_ENTERED_INCORRECTLY",
  ASSET_LEFT_THE_YARD: "ASSET_LEFT_THE_YARD",
});

export const InventoryCorrectionReasonLabels = Object.freeze([
  {value: inventoryCorrectionReasons.ASSET_DOES_NOT_BELONG_TO_BUYER, label: "Asset does not belong to me"},
  {value: inventoryCorrectionReasons.ASSET_CHECKED_OUT_ON_WRONG_DATE, label: "Asset was checked out on the wrong date"},
  {value: inventoryCorrectionReasons.ASSET_NUMBER_ENTERED_INCORRECTLY, label: "Asset number was entered incorrectly"},
  {value: inventoryCorrectionReasons.ASSET_LEFT_THE_YARD, label: "Asset left the yard"}
]);

export const InventoryCorrectionCheckinReasonLabels = Object.freeze([
  {value: inventoryCorrectionReasons.ASSET_DOES_NOT_BELONG_TO_BUYER, label: "Asset does not belong to me"},
  {value: inventoryCorrectionReasons.ASSET_NUMBER_ENTERED_INCORRECTLY, label: "Asset number was entered incorrectly"},
  {value: inventoryCorrectionReasons.ASSET_LEFT_THE_YARD, label: "Asset left the yard"}
]);

export const InventoryCorrectionCheckinCheckoutReasonLabels = Object.freeze([
  {value: inventoryCorrectionReasons.ASSET_DOES_NOT_BELONG_TO_BUYER, label: "Asset does not belong to me"},
  {value: inventoryCorrectionReasons.ASSET_CHECKED_OUT_ON_WRONG_DATE, label: "Asset was checked out on the wrong date"},
  {value: inventoryCorrectionReasons.ASSET_NUMBER_ENTERED_INCORRECTLY, label: "Asset number was entered incorrectly"}
]);

export const inventoryCorrectionStatus = Object.freeze({
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  CANCELLED: "CANCELLED"
})

export const inventoryAction = Object.freeze({CHECK_IN: "CHECK_IN", CHECK_OUT: "CHECK_OUT"})

export const AssetType = Object.freeze({
  CONTAINER_LOADED: "CONTAINER_LOADED",
  CONTAINER_EMPTY: "CONTAINER_EMPTY",
  CHASSIS_ONLY: "CHASSIS_ONLY",
  TRUCK_CONTAINER_LOADED: "TRUCK_CONTAINER_LOADED",
  TRUCK_TRAILER_LOADED: "TRUCK_TRAILER_LOADED",
  TRUCK_CONTAINER_EMPTY: "TRUCK_CONTAINER_EMPTY",
  TRUCK_TRAILER_EMPTY: "TRUCK_TRAILER_EMPTY",
  TRUCK_ONLY: "TRUCK_ONLY",
  TRUCK_ONLY_25: "TRUCK_ONLY_25",
  TRAILER_LOADED: "TRAILER_LOADED",
  TRAILER_EMPTY: "TRAILER_EMPTY",
  REEFER_LOADED_PLUGIN: "REEFER_LOADED_PLUGIN",
  REEFER_LOADED_NO_PLUGIN: "REEFER_LOADED_NO_PLUGIN",
  TRUCK_REEFER_LOADED_PLUGIN: "TRUCK_REEFER_LOADED_PLUGIN",
  TRUCK_REEFER_LOADED_NO_PLUGIN: "TRUCK_REEFER_LOADED_NO_PLUGIN",
});

export const AssetTypeLabel = Object.freeze({
  [AssetType.CONTAINER_LOADED]: "Container / Chassis (Loaded)",
  [AssetType.CONTAINER_EMPTY]: "Container / Chassis (Empty)",
  [AssetType.CHASSIS_ONLY]: "Chassis Only",
  [AssetType.TRUCK_CONTAINER_LOADED]: "Truck + Container (Loaded)",
  [AssetType.TRUCK_TRAILER_LOADED]: "Truck + Trailer (Loaded)",
  [AssetType.TRUCK_CONTAINER_EMPTY]: "Truck + Container / Chassis (Empty)",
  [AssetType.TRUCK_TRAILER_EMPTY]: "Truck + Trailer (Empty)",
  [AssetType.TRUCK_ONLY]: "Truck Only",
  [AssetType.TRUCK_ONLY_25]: "Truck Only (25')",
  [AssetType.TRAILER_LOADED]: "Trailer (Loaded)",
  [AssetType.TRAILER_EMPTY]: "Trailer (Empty)",
  [AssetType.REEFER_LOADED_PLUGIN]: "Refrigerated (Loaded) (Plug In)",
  [AssetType.REEFER_LOADED_NO_PLUGIN]: "Reefer (Loaded) (No Plug In)",
  [AssetType.TRUCK_REEFER_LOADED_PLUGIN]: "Truck + Reefer (Loaded) (Plug In)",
  [AssetType.TRUCK_REEFER_LOADED_NO_PLUGIN]: "Truck + Reefer (Loaded) (No Plug In)",
});
